
import {
  defineComponent,
  ref,
  toRefs,
  computed,
  unref
} from 'vue';
import { ElMessage, ElForm } from 'element-plus';
import { RuleItem } from 'async-validator';

import {
  createChannelAdmin,
  AuthRequestData,
  ResponseError
} from '@/services/api';
import { useFormErrors } from '@/composables/useFormErrors';
import { validatePassword } from '@/utils/validate';

const DEFAULT_FORM_VALUES: AuthRequestData = {
  username: '',
  password: ''
};

const RULES = {
  username: [
    {
      required: true
    }
  ],
  password: [
    {
      required: true,
      validator: (rule: RuleItem, password: string) => {
        if (!rule.required && !password) {
          return true;
        }

        return validatePassword(password);
      }
    }
  ]
};

export default defineComponent({
  props: {
    channelId: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { channelId, modelValue } = toRefs(props);
    const isVisible = computed({
      get: () => modelValue.value,
      set: (isVisible) => emit('update:modelValue', isVisible)
    });

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const clearForm = () => {
      formValues.value = { ...DEFAULT_FORM_VALUES };
    };

    const isLoading = ref(false);
    const { formErrors, bindFormItemError } = useFormErrors();
    const createAdmin = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate(async(valid: boolean) => {
        if (valid) {
          isLoading.value = true;
          try {
            await createChannelAdmin({
              channelId: channelId.value,
              data: formValues.value
            });
          } catch (_error) {
            const error = _error as ResponseError;
            formErrors.value = error.response?.data.errors;

            ElMessage.error(error.response?.data.message);
            isLoading.value = false;
            return;
          }

          ElMessage.success('success!');
          isLoading.value = false;

          clearForm();
          isVisible.value = false;
        }
      });
    };

    return {
      RULES,
      formRef,
      formValues,
      clearForm,
      isVisible,
      isLoading,
      createAdmin,
      bindFormItemError
    };
  }
});
