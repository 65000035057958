
import {
  defineComponent,
  ref,
  toRefs,
  computed,
  watch
} from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import permissionUnits from '@/components/permission-units/index.vue';
import {
  ChannelAdminIdOptions,
  getChannelAdmins,
  deleteChannelAdmin,
  ResponseError
} from '@/services/api';
import { ChannelAdmin } from '@/interfaces/ChannelAdmin';

export default defineComponent({
  components: {
    permissionUnits
  },
  props: {
    channelId: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { channelId, modelValue } = toRefs(props);
    const isVisible = computed({
      get: () => modelValue.value,
      set: (isVisible) => emit('update:modelValue', isVisible)
    });

    const admins = ref<ChannelAdmin[]>([]);
    const isLoading = ref(false);
    const fetchAdmins = async() => {
      isLoading.value = true;

      try {
        admins.value = (await getChannelAdmins({ channelId: channelId.value })).data;
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }

      isLoading.value = false;
    };

    watch(isVisible, (isVisible) => {
      if (isVisible) fetchAdmins();
    });

    const deleteAdmin = async({ adminId }: Partial<ChannelAdminIdOptions>) => {
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async() => {
          await deleteChannelAdmin({ channelId: channelId.value, adminId });

          ElMessage({
            type: 'success',
            message: 'Delete successfully'
          });

          fetchAdmins();
        })
        .catch();
    };

    return {
      admins,
      isLoading,
      isVisible,
      deleteAdmin
    };
  }
});
