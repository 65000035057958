import { ref } from 'vue';

// TODO: port form '@/utils/validate', should refactor
/**
 * el-form-item表單檢查
 * 顯示PHP laraval request error msg到表單item上
 * 使用方式見 IpWhitelistDetail.vue
 *
 */
export function useFormErrors() {
  /**
   * 錯誤的訊息
   * {
   *  title:['msg'],
   *  sort:['msg']
   * }
   *  */
  const formErrors = ref({});

  // 綁定:error後，propName檢查是否有該物件
  function bindFormItemError(propName: string) {
    return formErrors.value != null &&
      // eslint-disable-next-line no-prototype-builtins
      formErrors.value.hasOwnProperty(propName) &&
      formErrors.value[propName].length > 0
      ? formErrors.value[propName][0]
      : null;
  };

  return {
    formErrors,
    bindFormItemError
  };
}
